import crypto from 'crypto'
import { useMatches, useSearchParams } from '@remix-run/react'
import { redirect } from '@remix-run/server-runtime'
import { camelCase, compact } from 'lodash'
import { parse } from 'papaparse'
import { createContext, useMemo } from 'react'
import { convertUrlFiltersToES } from './filter.utils'
import { Activity, ActivityJson } from './models/Activity'
import { ContactJson } from './models/Contact'
import { UserRoles } from './models/framework/UserRoles'
import { LocationJson, LocationType } from './models/Location'
import { Organization } from 'app/models/Organization'
import type { User } from 'app/models/User'
import { Workspace } from 'app/models/Workspace'
import { debug } from 'lib/logging'
import { Jsonify } from 'lib/toJSON'

const DEFAULT_REDIRECT = `/`

export const PAGE_LIMIT = 1000
export const UUID_REGEX = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i

export type Falsy = null | undefined | false | 0 | -0 | ``

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
): string {
  if (!to || typeof to !== `string`) {
    return defaultRedirect
  }

  if (!to.startsWith(`/`) || to.startsWith(`//`)) {
    return defaultRedirect
  }

  return to
}

/**
 * This base hook is used in other hooks to quickly search for specific data
 * across all loader data using useMatches.
 * @param {string} id The route id
 * @returns {JSON|undefined} The router data or undefined if not found
 */
export function useMatchesData(id: string): Record<string, unknown> | undefined {
  const matchingRoutes = useMatches()
  const route: any = useMemo(() => matchingRoutes.find((route) => route.id === id), [matchingRoutes, id])
  return route?.data
}

export function useOptionalUser(routeId = `routes/__app`): Jsonify<User> | undefined {
  const data = useMatchesData(routeId)
  if (!data) {
    return undefined
  }
  return data.user as any
}

export function useWorkspace(): Jsonify<Workspace> | undefined {
  const data = useMatchesData(`routes/__app`)
  return data?.workspace as any
}
export function useOrganization(): Jsonify<Organization> | undefined {
  const data = useMatchesData(`routes/__app`)
  return data?.org as any
}

export function useUser(routeId?: string): Jsonify<User> {
  const maybeUser = useOptionalUser(routeId)
  if (typeof window !== `undefined` && !maybeUser) {
    window.location.replace(`/logout`)
    return {} as any
  } else if (!maybeUser) {
    throw redirect(`/login`)
  }
  return maybeUser
}

export function hasRole(user: { role?: number }, roleName?: keyof typeof UserRoles): boolean {
  if (!roleName) return true
  if (!user.role) {
    return false
  }
  return user.role >= UserRoles[roleName]
}

export function validateEmail(email: unknown): email is string {
  return typeof email === `string` && email.length > 3 && email.includes(`@`)
}

export async function setWorkspace(workspaceId: string | null, redirectTo?: string, tries = 0) {
  await fetch(`/api/users/me`, { body: JSON.stringify({ workspaceId }), method: `PATCH` })
    .then(() => {
      let url = new URL(document.location.origin + (redirectTo || `/`))
      url.searchParams.set(`wId`, workspaceId || `null`)
      location.assign(url.toString())
    })
    .catch((err) => {
      // eslint-disable-next-line no-console
      console.error(`error setting workspace`, err)
      if (tries > 3) throw err
      return setWorkspace(workspaceId, redirectTo, tries + 1)
    })
}

export async function setOrg(orgId: string | null, redirectTo?: string) {
  await fetch(`/api/users/me`, { body: JSON.stringify({ orgId }), method: `PATCH` }).then(() => {
    let url = new URL(document.location.origin + (redirectTo || `/`))
    url.searchParams.set(`wId`, `null`)
    location.assign(url.toString())
  })
}

export function useHandleSearchParams(): (param: { [key: string]: string | undefined }) => boolean {
  const setSearchParams = useSearchParams()[1]
  return (params: { [key: string]: string | undefined }) => {
    let searchParams = new URL(location.toString()).searchParams
    const allSearchParams = Object.fromEntries(searchParams.entries())
    let hasChanges = false
    for (const [key, value] of Object.entries(params)) {
      if (key === `page`) continue
      // eslint-disable-next-line eqeqeq -- we want undefined and null to be equal
      if (searchParams.get(key) != value) {
        hasChanges = true
        if (value === undefined) {
          delete params[key]
          delete allSearchParams[key]
        }

        //break
      }
    }

    if (hasChanges) {
      setSearchParams(
        {
          ...allSearchParams,
          ...params,
        } as any,
        { replace: false },
      )
    }
    return hasChanges
  }
}

export function getTableSearchParams(request: Request): {
  page: number
  terms: string[]
  sort?: string
  limit?: 0 | 1 | 100 | 1000
} {
  const { searchParams } = new URL(request.url)
  let page = Number(searchParams.get(`page`) || 0)
  let query = searchParams.get(`query`) ?? ``
  let join = searchParams.get(`join`)
  const urlFilters = convertUrlFiltersToES(searchParams.get(`filters`), join)
  const searchAndFilters = compact([query, ...urlFilters])
  let sort = searchParams.get(`sort`) ?? undefined
  if (!sort) {
    sort = undefined
  }
  const limit = (Number(searchParams.get(`limit`)) as 0 | 1 | 100 | 1000) || undefined
  return { page, terms: searchAndFilters, sort, limit }
}

export const ActivitiesFileHeaders = [
  `Workspace`,
  `Location`,
  `Vendor`,
  `Activity Type`,
  `Name`,
  `Activity Date`,
  `Requested Appointment Date`,
  `Phone`,
  `Email`,
  `Scheduled`,
  `Requested Treatment`,
  `Channel`,
  `Lead Owner`,
] as const

export type ImportFile = {
  workspace: string
  location: string
  vendor: string
  activityType: Activity[`type`] | string
  name: string
  activityDate: string
  requestedAppointmentDate: string
  phone: string
  email: string | undefined
  scheduled: `yes` | `no`
  requestedTreatment: string | undefined
  channel: string
  leadOwner: string | undefined
  workspaceId: string | undefined
  locationId: string | undefined
  locationName: string | undefined
  lineNumber: number
}

export function getTextFirstLine(text: string | undefined): string[] | undefined {
  if (!text) return undefined
  return text
    .slice(0, text.indexOf(`\n`) + 1)
    .replace(/\s|\*/g, ``)
    .split(`,`)
    ?.map((text) => camelCase(text))
}

export function csvToArray(csv: string | null): ImportFile[] | string {
  if (!csv) return `No file found`
  let rows: ImportFile[] = []
  parse(csv, {
    header: true,
    transformHeader: (header) => camelCase(header.replace(/\s|\*/g, ``)),
    complete: (results) => (rows = results.data as any),
  })
  return rows
}

export function md5(str: string) {
  let hash = crypto.createHash(`md5`).update(str).digest(`hex`)
  return hash
}

export function sha256(str: string) {
  debug(`sha`, `sha256`, str)
  if (!str) return undefined
  let hash = crypto.createHash(`sha256`).update(str).digest(`hex`)
  return hash
}

export function replaceCamelCase(role: string): string {
  return role.replace(/([A-Z])/g, ` $1`)
}

export function capitalizeCamelCase(str) {
  const words = str.match(/[A-Za-z][a-z]*/g)
  const capitalizedWords = words?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
  return capitalizedWords.join(` `)
}

export function sanitizeValue(value: string | string[] | undefined): string | string[] {
  return Array.isArray(value)
    ? value?.map((val) => val!.replace(/[+-=&&||><!(){}[]^"~*?:\\\/]/g, ``)) ?? ``
    : value?.replace(/[+-=&&||><!(){}[]^"~*?:\\\/]/g, ``) ?? ``
}

export function divide(left: number | undefined, right: number | undefined) {
  if (!left || !right) return 0
  return Math.round((left / right) * 100) / 100
}

export function getChannelColorMap(channel: ContactJson[`channel`]) {
  switch (channel) {
    case `Digital Marketing`:
      return `#3385ff`
    case `Referral Marketing`:
      return `#a049ff`
    case `Traditional Marketing`:
      return `#1ab9d9`
    default:
      return `#ffffff00`
  }
}

export function getLeadStatusColorMap(leadStatus: ContactJson[`leadStatus`]) {
  switch (leadStatus) {
    case `Needs Action`:
      return `#fdba74`
    case `Closed Won`:
      return `#3b82f6`
    case `In Progress`:
      return `#10B981`
    case `Closed Lost`:
      return `#F87171`
    case `Scheduled`:
      return `#22D3EE`
    default:
      return `#ffffff00`
  }
}

export function getActivityTypeColorMap(activityType: ActivityJson[`type`]) {
  switch (activityType) {
    case `Call`:
      return `#3b82f6`
    case undefined:
    case `Unknown`:
      return `#d3d3d3`
    case `Chat`:
      return `#10b981`
    case `Form`:
      return `#fdba74`
    case `Online Scheduler`:
      return `#22d3ee`
    default:
      return `#ffffff00`
  }
}

export function isLocationIntegrated(location?: LocationJson | LocationType) {
  debug(`location-integration`, `isLocationIntegrated`, location)
  if (!location) {
    return false
  }
  if (!location.sikkaOfficeId && !location.carestackId && !location.kollaConnectorId) {
    return false
  }
  return true
}

export function isSMCOrg(orgId: string) {
  return orgId === `5044d341-1053-4ffe-b6ad-5924a0dc5b60`
}

export const CollapsedContext = createContext(false)
